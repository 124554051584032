"use client";

import { useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";

export default function MyComponent() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const callback = searchParams.get("callbackUrl");

  useEffect(() => {
    if (callback) {
      router.push(callback);
    }
  }, [callback, router]);

  return null;
}
